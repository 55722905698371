import throttle from '@/js/helpers/throttle'
import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'

class Navigation {
  constructor () {
    this.body = document.body
    this.hamburgers = this.body.querySelectorAll('.nav-hamburger')
    this.wrapper = this.body.querySelector('.wrapper')
    this.menus = this.body.querySelectorAll('.nav--mobile, .nav--desktop')
    this.toggleElements = this.body.querySelectorAll('.navbar-toggle')
    this.active = false
    this.menuItems = this.body.querySelectorAll('.menu-item, .menu-button')
    this.linkElements = this.body.querySelectorAll('a:not([target="_blank"]):not([href^="#"]):not([href^="mailto:"]):not([href^="tel:"])')

    this.initEvents()
  }

  toggleMenu () {
    this.active = !this.active
    // And toggle the active class
    this.body.classList.toggle('body--slide-active', this.active)

    for (let i = 0; i < this.menus.length; i++) {
      this.menus[i].classList.toggle('nav--active', this.active)
    }

    for (let i = 0; i < this.hamburgers.length; i++) {
      this.hamburgers[i].classList.toggle('nav-hamburger--is-active', this.active)
    }
  }

  deactivateMenu () {
    this.active = false
    // And toggle the active class
    this.body.classList.toggle('body--slide-active', this.active)

    for (let i = 0; i < this.menus.length; i++) {
      this.menus[i].classList.toggle('nav--active', this.active)
    }

    for (let i = 0; i < this.hamburgers.length; i++) {
      this.hamburgers[i].classList.toggle('nav-hamburger--is-active', this.active)
    }
  }

  headerScroll (ws = 0) {
    const isScroll = ws > 30

    this.body.classList.toggle('menu-scroll', isScroll)

    for (let i = 0; i < this.menus.length; i++) {
      this.menus[i].classList.toggle('menu-scroll', isScroll)
    }
  }

  outFn (url = window.location.href) {
    for (let i = 0; i < this.menus.length; i++) {
      this.menus[i].classList.add('is-unloaded')
    }

    const containers = document.querySelectorAll('.l .container')

    if (containers.length) {
      gsap.to(containers, {
        ease: 'expo.out',
        duration: 0.4,
        opacity: 0
      })
      setTimeout(() => {
        window.location.href = url
      }, 400)
    } else {
      window.location.href = url
    }
  }

  initEvents () {
    for (let i = 0; i < this.menus.length; i++) {
      gsap.fromTo(this.menus[i], {
        opacity: 1,
        y: '-100%'
      }, {
        ease: 'expo.out',
        duration: 0.4,
        y: 0,
        clearProps: 'transform'
      })
    }

    const clickHandler = () => this.toggleMenu()

    for (let i = 0; i < this.toggleElements.length; i++) {
      this.toggleElements[i].addEventListener('click', clickHandler)
    }

    for (let i = 0; i < this.menuItems.length; i++) {
      this.menuItems[i].addEventListener('click', this.deactivateMenu.bind(this))
    }

    window.addEventListener('scroll', throttle(() => {
      const ws = window.pageYOffset
      this.headerScroll(ws)
    }, 100))

    this.headerScroll()

    const outHandler = url => this.outFn(url)

    for (let i = 0; i < this.linkElements.length; i++) {
      this.linkElements[i].addEventListener('click', function (e) {
        const url = this.href

        if (url.includes('#')) {
          return
        }

        e.preventDefault()

        if (e.which === 2 || e.metaKey || e.shiftKey || (navigator.platform.toUpperCase().indexOf('WIN') !== -1 && e.ctrlKey)) {
          window.open(url, '_blank')
        } else {
          outHandler(url)
        }
      })
    }
  }
}

docReady(() => {
  // eslint-disable-next-line
  const nav = new Navigation()
})
