import docReady from '@/js/helpers/doc-ready'

const observer = new window.IntersectionObserver((entries) => {
  entries.forEach(entry => {
    const el = entry.target
    const id = el.id
    const menuItems = document.querySelectorAll('a[href$=' + id + ']')

    Array.from(menuItems).forEach(menuItem => {
      menuItem.parentNode.classList.toggle('active', entry.isIntersecting)
    })
  })
}, {
  treshold: [0, 0.25, 0.5, 0.75, 1],
  rootMargin: '-150px 0px -150px 0px'
})

docReady(() => {
  const sections = document.querySelectorAll('.report-hero[id], .report-l[id], .l[id]')

  if (sections.length && 'IntersectionObserver' in window) {
    Array.from(sections).forEach(section => {
      observer.observe(section)
    })
  }
})
