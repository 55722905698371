import { gsap } from 'gsap'

function creatAlert (text, extraClass = '') {
  const alert = document.createElement('div')
  alert.className = `alert ${extraClass}`
  alert.innerText = text

  document.body.appendChild(alert)

  gsap.fromTo(alert, {
    opacity: 0,
    y: '-100%'
  }, {
    duration: 0.5,
    ease: 'expo.out',
    opacity: 1,
    y: 0,
    onComplete: () => {
      gsap.to(alert, {
        duration: 0.3,
        delay: 3,
        ease: 'expo.inOut',
        opacity: 0,
        y: '-100%',
        onComplete: () => {
          document.body.removeChild(alert)
        }
      })
    }
  })
}

export default creatAlert
