import docReady from '@/js/helpers/doc-ready'

const regularObserver = new window.IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      const el = entry.target
      observer.unobserve(el)
      animate(el)
    }
  })
}, {
  treshold: 1,
  rootMargin: '-10% 0px -15% 0px'
})

docReady(() => {
  if ('IntersectionObserver' in window) {
    Array.from(document.querySelectorAll('.ballpark .number')).forEach(el => {
      regularObserver.observe(el)
    })
  } else {
    Array.from(document.querySelectorAll('.ballpark .number')).forEach(el => {
      animate(el)
    })
  }
})

const speed = 1000

const animate = counter => {
  const value = +counter.getAttribute('data-akhi')
  const data = +counter.innerText
  const time = value / speed

  if (data < value) {
    counter.innerText = Math.ceil(data + time)
    setTimeout(() => {
      animate(counter)
    }, 400 / value)
  } else {
    counter.innerText = value
  }
}
