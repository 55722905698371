import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'
import { isEmptyObject } from '@/js/helpers/utilities'
import creatAlert from '@/js/components/alert'
import Spotify from '@/js/components/Spotify'

// Example track URL
// https://open.spotify.com/track/5CTaFr3yPs4SEtCMt70Hfv?si=af658f14c3d449ad
class RingoSongReport extends Spotify {
  constructor (el) {
    super()
    // Elements
    this.el = el

    // Spotify els
    this.spotifyForm = this.el.querySelector('[data-rsr-spotify-form]')
    this.errorWrapper = this.el.querySelector('[data-rsr-spotify-error]')
    this.urlInput = this.el.querySelector('[data-rsr-spotify-url]')
    this.listEl = this.el.querySelector('[data-rsr-list]')
    this.countEl = this.el.querySelector('[data-rsr-num]')
    this.pagenumEls = document.querySelectorAll('[data-rsr-pagenum]')

    // Content
    this.leftContentEl = this.el.querySelector('[data-rsr-left]')
    this.introEl = this.el.querySelector('[data-rsr-intro]')
    this.flowEl = this.el.querySelector('[data-rsr-flow]')

    // Form
    this.currentPage = null
    this.pages = this.el.querySelectorAll('.gform_page')
    this.prevButton = this.el.querySelector('[data-rsr-prev]')
    this.nextButton = this.el.querySelector('[data-rsr-next]')
    this.progressEl = document.querySelector('[data-rsr-progress]')
    this.detailsEl = this.el.querySelector('[data-rsr-details]')
    this.loadingEl = this.el.querySelector('[data-rsr-loading]')
    this.spotifyAdminIdsStr = '.rsr-field-spotify-ids input[type="text"]'
    this.cloneEl = this.el.querySelector('[data-rsr-clone]')

    // Form el strings
    this.spotifyAdminUrlsStr = '.rsr-field-spotify-urls textarea'
    this.quantityElStr = '.rsr-field-quantity .ginput_quantity'
    this.totalElStr = '.rsr-field-price .ginput_total'

    // Fields
    this.fields = {
      fname: { fieldValue: '', el: '.rsr-field-fname input[type="text"]', event: 'change' },
      lname: { fieldValue: '', el: '.rsr-field-lname input[type="text"]', event: 'change' },
      company: { fieldValue: '', el: '.rsr-field-company input[type="text"]', event: 'change' },
      email: { fieldValue: '', el: '.rsr-field-email input[type="text"]', event: 'change' },
      phone: { fieldValue: '', el: '.rsr-field-phone input[type="text"]', event: 'change' },
      consent: { fieldValue: '', el: '.rsr-field-consent input[type="checkbox"]', event: 'change' },
      projectTitle: { fieldValue: '', el: '.rsr-field-project-title input[type="text"]', event: 'change', label: 'Project title' },
      brand: { fieldValue: '', el: '.rsr-field-brand input[type="text"]', event: 'change', label: 'Brand' },
      licensedFrom: { fieldValue: '', el: '.rsr-field-licensed-from input[type="text"]', event: 'change', label: 'Licensed from', type: 'multi-select' },
      // territories: { fieldValue: '', el: '.rsr-field-territories select', event: 'change', label: 'Territories', type: 'multi-select' },
      // territoriesAdv: { fieldValue: '', el: '.rsr-field-territories .chosen-search-input', event: 'change', label: 'Territories', type: 'multi-select-adv' },
      // duration: { fieldValue: '', el: '.rsr-field-duration select', event: 'change', label: 'Duration' },
      // exclusivity: { fieldValue: '', el: '.rsr-field-exclusivity .gchoice input[type="radio"]', event: 'change', label: 'Exclusivity', type: 'radio' },
      medium: { fieldValue: '', el: '.rsr-field-medium .gchoice input[type="checkbox"]', event: 'change', label: 'Media', type: 'checkbox' },
      // version: { fieldValue: '', el: '.rsr-field-version .gchoice input[type="checkbox"]', event: 'change', label: 'Version', type: 'checkbox' },
      file: { fieldValue: 0, el: '.rsr-field-file input[type="file"]', event: 'change', type: 'file' }
      // Populated
      // total: { fieldValue: '', el: '.rsr-field-price .ginput_total', event: 'change', type: 'total' }
    }

    // Form id
    this.getFormId()

    // Authenticate first
    this.auth()

    // Check URL for spotify param
    this.checkParams()

    // Init form events
    this.initEvents()

    // Set correct navigation state
    this.pageLoaded(null)
  }

  getTl () {
    return gsap.timeline({
      defaults: {
        duration: 0.4,
        ease: 'expo.out'
      }
    })
  }

  getFormId () {
    const formWrapper = this.el.querySelector('.gform_wrapper')
    if (formWrapper) {
      const idParts = formWrapper.id.split('_')
      this.formId = idParts[idParts.length - 1]
    }
  }

  async setTrackList () {
    // Get all the track from this.songIds and Spotify API
    await this.getTracks()

    // Ids length is correct
    // Do something with length
    this.el.classList.toggle('not-multiple-tracks', this.songIds.length <= 1)

    const afterRemoval = () => {
      // Empty tracklist
      this.listEl.innerHTML = ''

      // Update list count
      const total = this.tracks.length < 50 ? this.tracks.length : 50
      this.countEl.innerHTML = total

      // If no ids/tracks in stack
      if (!this.tracks || !this.tracks.length) {
        console.warn('No tracks available.')
        this.setAlert('No tracks available')
        return
      }

      const spotifyAdminIds = document.querySelector(this.spotifyAdminIdsStr)
      const spotifyAdminUrls = document.querySelector(this.spotifyAdminUrlsStr)
      const adminIds = []
      let adminUrls = ''

      // Loop over tracks
      // And add them to the list
      for (let i = 0; i < total; i++) {
      // Current track
        const track = this.tracks[i]

        // Get artist name
        const artists = track.artists.map(artist => artist.name).join(', ')

        // Get track id
        const trackId = this.getIdFromSpotifyUrl(track.href)

        adminIds.push(trackId)
        adminUrls += `https://open.spotify.com/track/${trackId}\n`

        const hasImage = track?.album?.images[1]?.url
        const hasImageClass = hasImage ? 'rsr-li--has-image' : 'rsr-li--no-image'

        // HTML string
        let listItemStr = `<li class="rsr-li ${hasImageClass}">`

        if (hasImage) {
          listItemStr += `<div class="rsr-li__media ${hasImageClass}__media" style="background-image: url(${track.album.images[1].url});"></div>`
        }

        listItemStr += `<div class="rsr-li__entry ${hasImageClass}__entry">
          <h3 class="rsr-li__title h4">${track.name}</h3>
          <p class="rsr-li__artists">${artists}</p>
          <button class="rsr-li__remove" data-track-id="${trackId}">
            <svg height="9.414" viewBox="0 0 9.414 9.414" width="9.414" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10"><path d="m.707.707 8 8"/><path d="m8.707.707-8 8"/></g></svg>
          </button>
        </div>
      </li>`

        // Add HTML string to list
        this.listEl.insertAdjacentHTML('beforeend', listItemStr)
      }

      gsap.fromTo('.rsr-songs__entry .rsr-li', {
        y: -10,
        opacity: 0
      }, {
        duration: 0.2,
        ease: 'expo.out',
        stagger: 0.05,
        y: 0,
        opacity: 1
      })

      this.cloneEl.innerHTML = this.listEl.innerHTML

      // Set textarea
      // Readable urls for ringo
      if (spotifyAdminUrls) {
        spotifyAdminUrls.value = adminUrls
      }

      // Set text field
      // String of ids for development
      if (spotifyAdminIds) {
        spotifyAdminIds.value = adminIds.join(',')
      }

      this.updatePrice()
    }

    gsap.to('.rsr-songs__entry .rsr-li', {
      duration: 0.2,
      ease: 'expo.out',
      stagger: 0.03,
      y: -10,
      opacity: 0,
      onComplete: afterRemoval
    })
  }

  updatePrice () {
    // Set total price based on amount of tracks
    const quantityField = document.querySelector(this.quantityElStr)
    if (quantityField) {
      quantityField.value = this.songIds.length
    }

    this.updateSidebar(false, false, true)
  }

  setAlert (error, lightTheme = false) {
    console.error(error)
    creatAlert(error, lightTheme ? 'alert--light' : '')
  }

  async checkParams () {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    if (urlParams.has('spotify')) {
      const spotifyFromUrl = urlParams.get('spotify')
      await this.auth()
      this.submitForm(null, spotifyFromUrl)
    }
  }

  async submitForm (e, url = false) {
    if (e && e.type === 'submit') {
      e.preventDefault()
    }

    // Reset the input
    this.urlInput.value = ''

    if (!url) {
      this.setAlert('This is an invalid Spotify URL')
      return
    }

    const trackId = this.getIdFromSpotifyUrl(url)

    if (this.songIds.includes(trackId)) {
      this.setAlert('This track has already been added')
      return
    }

    // Push id to ids array
    if (!this.songIds.includes(trackId) && trackId) {
      this.songIds.push(trackId)

      this.setAlert('Track added', true)
    }

    this.setTrackList()
  }

  pageLoaded (currentPage) {
    const oldPageNum = this.currentPage === null ? null : +this.currentPage
    // code to be trigger when next/previous page is loaded
    this.currentPage = currentPage === null ? null : +currentPage

    const tl = this.getTl()
    const isFinalPage = this.currentPage === this.pages.length

    // Loading animation
    tl.to(this.loadingEl, {
      autoAlpha: 0,
      display: 'none'
    })

    const goingNext = (oldPageNum === null) || oldPageNum < this.currentPage

    if (this.currentPage !== null) {
      const pageEl = document.getElementById(`gform_page_${this.formId}_${this.currentPage}`)
      tl.fromTo(pageEl, { opacity: 0, x: goingNext ? 100 : -100 }, { opacity: 1, x: 0 }, 0)
    }

    // Set buttons
    this.prevButton.disabled = this.currentPage == null

    // Toggle classes
    this.el.classList.toggle('is-paged', this.currentPage !== null)
    this.el.classList.toggle('is-last-page', isFinalPage)
    this.nextButton.innerHTML = isFinalPage ? '<span class="button__span">Choose a payment method</span>' : '<span class="button__span">Next <svg xmlns="http://www.w3.org/2000/svg" width="5.152" height="8.89"><g><path d="M.707.707l3.738 3.738L.707 8.183" fill="none" stroke="#fff" stroke-linecap="square" stroke-miterlimit="10"/></g></svg></span>'

    // Percentage progressbar update
    const completionPercentage = (this.currentPage === null ? 0 : ((this.currentPage / this.pages.length) * 100).toFixed(2)) + '%'
    tl.to(this.progressEl, { x: completionPercentage }, 0)

    // Step pagenum update
    Array.from(this.pagenumEls).forEach(el => {
      el.innerHTML = this.currentPage ? `${this.currentPage}/${this.pages.length}` : ''
    })

    this.updatePrice()
  }

  previousPage () {
    const tl = this.getTl()

    // Loading animation
    tl.to(this.loadingEl, {
      autoAlpha: 1,
      display: 'block'
    }, 0)

    if (this.currentPage === 1) {
      tl.to(this.flowEl, {
        autoAlpha: 0,
        display: 'none'
      }, 0)
      tl.to(this.introEl, {
        autoAlpha: 1,
        display: 'block'
      })

      this.pageLoaded(null)
    } else {
      const pageEl = document.getElementById(`gform_page_${this.formId}_${this.currentPage}`)
      const realPrevButton = pageEl.querySelector('.gform_previous_button.button')

      tl.to(pageEl, {
        opacity: 0.3,
        onComplete: () => {
          // Click the button
          realPrevButton.click()
        }
      }, 0)
    }
  }

  nextPage () {
    const tl = this.getTl()

    // Loading animation
    tl.to(this.loadingEl, {
      autoAlpha: 1,
      display: 'block'
    }, 0)

    if (this.currentPage == null) {
      tl.to(this.introEl, {
        autoAlpha: 0,
        display: 'none'
      }, 0)
      tl.to(this.flowEl, {
        autoAlpha: 1,
        display: 'block'
      })

      this.pageLoaded(1)
    } else if (this.currentPage < this.pages.length) {
      const pageEl = document.getElementById(`gform_page_${this.formId}_${this.currentPage}`)
      const realNextButton = pageEl.querySelector('.gform_next_button.button')

      tl.to(pageEl, {
        opacity: 0.3,
        onComplete: () => {
          // Click the button
          realNextButton.click()
        }
      }, 0)
    } else {
      const pageEl = document.getElementById(`gform_page_${this.formId}_${this.currentPage}`)
      const realSubmitButton = pageEl.querySelector(`#gform_submit_button_${this.formId}`)

      tl.to(pageEl, {
        opacity: 0.3,
        onComplete: () => {
          // Click the button
          realSubmitButton.click()
        }
      }, 0)
    }
  }

  async removeTrack (trackId) {
    if (this.songIds.length === 1) {
      this.setAlert('Can\'t remove last track. At least one track is needed to create a report.')
      return
    }

    // Index of trackId in ids array
    const indexInArray = this.songIds.indexOf(trackId)

    // If track id exists in array
    if (indexInArray > -1) {
      this.setAlert('Track removed', true)
      // Remove from array
      this.songIds.splice(indexInArray, 1)
      // Set track list
      this.setTrackList()
    }
  }

  updateSidebar (e = false, fieldObj = false, force = false, val = false) {
    if (e && fieldObj) {
      let val = e.target.value

      if (fieldObj.type === 'multi-select') {
        const selectedOptions = [...e.target.options].filter((x) => x.selected).map((x) => x.value)
        val = selectedOptions.join(', ')
      }

      if (fieldObj.type === 'checkbox') {
        const checkboxEls = document.querySelectorAll(fieldObj.el)
        const checkedValuesArr = Array.prototype.slice.call(checkboxEls).filter(ch => ch.checked).map(el => el.value)
        val = checkedValuesArr.join(', ')
      }

      // if (fieldObj.type === 'file') {
      //   // const fileEl = document.querySelector(fieldObj.el)
      //   const fileFieldEl = document.querySelector('.rsr-field-file')
      //   console.log(fileFieldEl.querySelectorAll('.ginput_preview'))
      // }

      fieldObj.fieldValue = val
    }

    const filteredFields = Object.fromEntries(Object.entries(this.fields).filter(([key, value]) => {
      return !!this.fields[key].fieldValue
    }))

    const afterRemoval = () => {
      // Start off with empty string
      this.detailsEl.innerHTML = ''
      let detailsStr = ''

      // If any filled in fields
      if (!isEmptyObject(filteredFields)) {
        detailsStr += `<header class="rsr-details-header">
        <h2 class="rsr-details-header__title">Your Project Terms</h2>`

        for (const fieldName in filteredFields) {
          const updatedFieldObj = filteredFields[fieldName]

          if (fieldName === 'fname') {
            detailsStr += `<span class="rsr-details-header__name">${updatedFieldObj.fieldValue}</span>`
          }

          if (fieldName === 'lname') {
            detailsStr += `<span class="rsr-details-header__name">${updatedFieldObj.fieldValue}</span>`
          }

          if (fieldName === 'company') {
            detailsStr += `<span class="rsr-details-header__company">${updatedFieldObj.fieldValue}</span>`
          }
        }

        detailsStr += '</header>'

        // Start the datalist
        detailsStr += '<dl class="rsr-dl">'

        // Loop over filled in fields
        for (const fieldName in filteredFields) {
          const updatedFieldObj = filteredFields[fieldName]

          // HTML string
          if (updatedFieldObj.label) {
            detailsStr += `<div class="rsr-dl__part">
            <dt class="rsr-dl__dt ringo-small">${updatedFieldObj.label}</dt>
            <dd class="rsr-dl__dd">${updatedFieldObj.fieldValue}</dd>
          </div>`
          }

          if (updatedFieldObj.type === 'file') {
            detailsStr += `<div class="rsr-dl__part">
            <dt class="rsr-dl__dt ringo-small">Attached</dt>
            <dd class="rsr-dl__dd">${updatedFieldObj.fieldValue}</dd>
          </div>`
          }
        }

        // Close the datalist
        detailsStr += '</dl>'
      }

      const totalEl = document.querySelector(this.totalElStr)

      detailsStr += `<div class="rsr-total">
      <div class="rsr-total__label ringo-small">Total</div>
      <div class="rsr-total__total">${totalEl.value}</div>
    </div>`

      // Add HTML string to details element
      // this.detailsEl.innerHTML = detailsStr
      this.detailsEl.insertAdjacentHTML('beforeend', detailsStr)

      if (!force) {
        gsap.to(this.detailsEl, {
          duration: 0.4,
          ease: 'expo.out',
          opacity: 1
        })
      }
    }

    if (force) {
      afterRemoval()
    } else {
      gsap.to(this.detailsEl, {
        duration: 0.6,
        ease: 'expo.inOut',
        opacity: 0,
        onComplete: afterRemoval
      })
    }
  }

  initEvents () {
    // Submit Spotify form event
    const submitHandler = e => this.submitForm(e, this.urlInput.value)
    this.spotifyForm.addEventListener('submit', submitHandler)

    // Page navigation events
    this.prevButton.addEventListener('click', this.previousPage.bind(this))
    this.nextButton.addEventListener('click', this.nextPage.bind(this))

    // Check for removal of tracks
    const docClickHandler = e => {
      if (e.target && e.target.getAttribute('data-track-id')) {
        const trackId = e.target.getAttribute('data-track-id')
        this.removeTrack(trackId)
      }
    }
    document.addEventListener('click', docClickHandler)

    // Loop over form fields
    if (this.fields) {
      for (const fieldName in this.fields) {
        const fieldObj = this.fields[fieldName]

        // If element exists
        if (fieldObj.el && fieldObj.event) {
          const eventHandler = e => {
            const els = document.querySelectorAll(fieldObj.el)
            if (els.length) {
              for (let i = 0; i < els.length; i++) {
                const el = els[i]
                if (e.target === el) {
                  this.updateSidebar(e, fieldObj)
                }
              }
            }
          }

          document.addEventListener(fieldObj.event, eventHandler)
        }
      }
    }

    // Everytime a new form page is loaded
    // eslint-disable-next-line
    jQuery(document).bind('gform_page_loaded', (e, formId, currentPage) => {
      this.pageLoaded(currentPage)
    })

    // window.gform.addAction('gform_input_change', (elem, formId, fieldId) => {
    //   if (elem.type === 'select-multiple') {
    //     const selectedOptions = [...elem.options].filter((x) => x.selected).map((x) => x.value)
    //     const val = selectedOptions.join(', ')

    //     this.fields.territories.fieldValue = val
    //     this.updateSidebar()
    //   }
    // }, 10, 3)

    window.gform.addFilter('gform_product_total', (total, formId) => {
      this.updatePrice()
      return total
    })
  }
}

docReady(() => {
  const rsrEls = document.querySelectorAll('[data-rsr]')
  if (rsrEls.length) {
    for (let i = 0; i < rsrEls.length; i++) {
      // eslint-disable-next-line
      const ringoSongReport = new RingoSongReport(rsrEls[i])
    }
  }
})
