import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'
import Flickity from 'flickity-fade'

class Accordion {
  constructor (el, closeText = 'Close') {
    this.el = el
    this.toggler = this.el.querySelector('[data-accordion-toggle]')
    this.isTallEnough = this.el.scrollHeight > 500
    this.open = false
    this.closeText = closeText
    this.openText = this.toggler.innerText

    this.tl = () => gsap.timeline({
      defaults: {
        duration: this.open ? 1 : 0.6,
        ease: 'expo.out'
      }
    })

    this.init()
    this.initEvents()
  }

  toggle () {
    const tl = this.tl()
    this.open = !this.open

    if (this.open) {
      tl.to(this.el, {
        height: this.el.scrollHeight
      })
      tl.set(this.el, {
        clearProps: 'all'
      })
    } else if (!this.open && this.isTallEnough) {
      tl.to(this.el, {
        height: 500
      })
    }

    this.toggler.querySelector('.button__span').innerText = this.open ? this.closeText : this.openText
    // this.toggler.innerText = this.open ? this.closeText : this.openText

    this.el.classList.toggle('accordion-open', this.open)
  }

  init () {
    if (this.isTallEnough) {
      const tl = this.tl()
      tl.set(this.el, {
        height: 500
      })

      this.el.classList.add('accordion-initialized')
    }
  }

  initEvents () {
    if (this.isTallEnough) {
      const clickHandler = e => this.toggle(e)
      this.toggler.addEventListener('click', clickHandler)
    }
  }
}

class Full {
  constructor (el) {
    this.el = el
    this.panel = this.el.querySelector('[data-full-panel]')
    this.toggler = this.el.querySelector('[data-full-toggle]')
    this.close = this.el.querySelector('[data-full-close]')
    this.slider = this.el.querySelector('[data-slider-slides]')
    this.open = false

    this.tl = () => gsap.timeline({
      defaults: {
        duration: 0.4,
        ease: 'expo.out'
      }
    })

    this.initEvents()
  }

  resizeSlider () {
    if (this.slider) {
      const flkty = Flickity.data(this.slider)

      if (flkty) {
        flkty.resize()
      }
    }
  }

  async openFull () {
    if (this.open) {
      return
    }

    const tl = this.tl()

    this.open = true
    this.el.classList.add('is-open')

    await tl.set(this.panel, {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: '1100',
      borderRadius: 0,
      height: '100%',
      overflow: 'auto',
      scale: 0.95
    }, 0)

    this.resizeSlider()

    tl.set(document.body, {
      overflow: 'hidden'
    })

    tl.to(this.panel, {
      duration: 0.6,
      scale: 1,
      opacity: 1,
      clearProps: 'transform'
    })

    tl.to(this.toggler, {
      autoAlpha: 0
    }, 0)

    tl.to(this.close, {
      autoAlpha: 1
    })
  }

  async closeFull () {
    if (!this.open) {
      return
    }

    const tl = this.tl()

    this.open = false
    this.el.classList.remove('is-open')

    tl.to(this.close, {
      autoAlpha: 0
    }, 0)

    tl.to(this.toggler, {
      autoAlpha: 1
    }, 0)

    tl.set(this.panel, {
      clearProps: 'all'
    }, 0)

    tl.set(document.body, {
      clearProps: 'all'
    }, 0)

    this.resizeSlider()
  }

  initEvents () {
    this.toggler.addEventListener('click', () => this.openFull())
    this.close.addEventListener('click', () => this.closeFull())
  }
}

docReady(() => {
  Array.from(document.querySelectorAll('[data-accordion]')).forEach(el => {
    // eslint-disable-next-line
    const accordion = new Accordion(el)
  })

  Array.from(document.querySelectorAll('[data-full]')).forEach(el => {
    // eslint-disable-next-line
    const accordion = new Full(el)
  })
})
