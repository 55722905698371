import docReady from '@/js/helpers/doc-ready'
import createSlider from '@/js/helpers/create-slider'

const baseArgs = {
  percentPosition: true,
  pageDots: true,
  prevNextButtons: true,
  wrapAround: true
}

docReady(() => {
  Array.from(document.querySelectorAll('.gallery')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      percentPosition: false,
      prevNextButtons: false,
      wrapAround: false,
      groupCells: false,
      cellAlign: 'center',
      fade: true,
      adaptiveHeight: true,
      autoPlay: 3000
    })
  })

  Array.from(document.querySelectorAll('.home-gallery')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      percentPosition: true,
      prevNextButtons: false,
      wrapAround: true,
      groupCells: false,
      cellAlign: 'center',
      fade: true,
      adaptiveHeight: false,
      pageDots: true
    })
  })

  Array.from(document.querySelectorAll('.example-images')).forEach(el => {
    createSlider(el, {
      ...baseArgs,
      percentPosition: false,
      prevNextButtons: false,
      wrapAround: true,
      groupCells: false,
      cellAlign: 'center',
      pageDots: false,
      autoPlay: 3000
    })
  })
})
