import docReady from '@/js/helpers/doc-ready'
import Spotify from '@/js/components/Spotify'

class ArtistCard extends Spotify {
  constructor (el) {
    super()

    this.el = el
    this.url = el.getAttribute('data-artist-url')

    this.init()
  }

  setArtistIds () {
    const id = this.getIdFromSpotifyUrl(this.url)
    this.artistIds.push(id)
  }

  getArtist () {
    return this.artists.find(artist => artist.id)
  }

  setCard () {
    console.log('artist', this.artist)
    this.el.innerHTML = `<div class="artist-card__media" style="background-image: url(${this.artist.images[2].url});"></div>
      <div class="artist-card__entry">
        <p class="artist-card__title">${this.artist.name}</p>
    </div>`
  }

  async init () {
    await this.auth()
    this.setArtistIds()
    await this.getArtists(this.artistIds)
    this.artist = this.getArtist()
    this.setCard()
  }
}

class SongCard extends Spotify {
  constructor (el) {
    super()

    this.el = el
    this.url = el.getAttribute('data-song-url')

    this.init()
  }

  setSongId () {
    const id = this.getIdFromSpotifyUrl(this.url)
    this.songIds.push(id)
  }

  getTrack () {
    return this.tracks.find(track => track.id)
  }

  setCard () {
    this.el.style.backgroundImage = `url(${this.song.album.images[1].url})`
    this.el.classList.add('not-loading')
  }

  async init () {
    await this.auth()
    this.setSongId()
    await this.getTracks(this.songIds)
    this.song = this.getTrack()
    console.log('song', this.song)
    this.setCard()
  }
}

class SongAlbum extends Spotify {
  constructor (el) {
    super()

    this.el = el
    this.url = el.getAttribute('data-song-album')

    this.init()
  }

  setSongId () {
    const id = this.getIdFromSpotifyUrl(this.url)
    this.songIds.push(id)
  }

  getTrack () {
    return this.tracks.find(track => track.id)
  }

  setAlbum () {
    this.el.innerHTML = `${this.song.album.name} - ${this.song.album.type}`
    this.el.classList.add('not-loading')
  }

  async init () {
    await this.auth()
    this.setSongId()
    await this.getTracks(this.songIds)
    this.song = this.getTrack()
    this.setAlbum()
  }
}

class SongRelease extends Spotify {
  constructor (el) {
    super()

    this.el = el
    this.url = el.getAttribute('data-song-release')

    this.init()
  }

  setSongId () {
    const id = this.getIdFromSpotifyUrl(this.url)
    this.songIds.push(id)
  }

  getTrack () {
    return this.tracks.find(track => track.id)
  }

  setRelease () {
    this.el.innerHTML = `${this.song.album.release_date}`
    this.el.classList.add('not-loading')
  }

  async init () {
    await this.auth()
    this.setSongId()
    await this.getTracks(this.songIds)
    this.song = this.getTrack()
    this.setRelease()
  }
}

docReady(() => {
  Array.from(document.querySelectorAll('[data-artist-url]')).forEach(el => {
    // eslint-disable-next-line
    const artistCard = new ArtistCard(el)
  })

  Array.from(document.querySelectorAll('[data-song-url]')).forEach(el => {
    // eslint-disable-next-line
    const songCard = new SongCard(el)
  })

  Array.from(document.querySelectorAll('[data-song-album]')).forEach(el => {
    // eslint-disable-next-line
    const songAlbum = new SongAlbum(el)
  })

  Array.from(document.querySelectorAll('[data-song-release]')).forEach(el => {
    // eslint-disable-next-line
    const songRelease = new SongRelease(el)
  })
})
