import { SPOTIFY_CLIENT, SPOTIFY_SECRET } from '@env'
import createAlert from '@/js/components/alert'

class Spotify {
  constructor () {
    this.token = null

    // Spotify misc.
    this.base = 'https://api.spotify.com/v1' // tracks, artists, albums
    this.songIds = []
    this.tracks = []
    this.artistIds = []
    this.artists = []
    this.playlistId = null
    this.playlist = null
  }

  async auth () {
    if (this.token) {
      return
    }

    // Authenticate
    const result = await window.fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + window.btoa(SPOTIFY_CLIENT + ':' + SPOTIFY_SECRET)
      },
      body: 'grant_type=client_credentials'
    })

    const data = await result.json()
    this.token = data.access_token
  }

  getIdFromSpotifyUrl (href) {
    let trackId

    // https://open.spotify.com/playlist/37i9dQZF1DX55dNU0PWnO5?si=1b3568aea62e45b5
    try {
      const urlObj = new URL(href)

      if (urlObj.host !== 'open.spotify.com' && urlObj.host !== 'api.spotify.com') {
        if (urlObj.host.includes('spotify')) {
          createAlert('This is an invalid Spotify URL')
        } else {
          createAlert('This is not a Spotify URL')
        }

        return
      }

      const trackParts = urlObj.pathname.split('/')

      trackId = trackParts[trackParts.length - 1]
    } catch (error) {
      createAlert('This is an invalid Spotify URL')
      return
    }

    return trackId
  }

  // Get playlist
  async getPlaylist () {
    if (!this.playlistId) {
      console.warn('No playlists added.')
      this.playlist = null
      return
    }

    // Can only fetch single playlist
    await window.fetch(`${this.base}/playlists/${this.playlistId}`, {
      method: 'get',
      headers: new window.Headers({
        Authorization: 'Bearer ' + this.token
      })
    })
      .catch(error => {
        console.error(error)
        createAlert(error)
      })
      .then(response => response.json())
      .then(res => {
        this.playlistId = null
        let containsInvalidTracks = false

        if (res && res.tracks && res.tracks.items) {
          if (res.tracks.total && res.tracks.total > 50) {
            createAlert('Can only add the first 50 tracks.')
          }

          const total = res.tracks.items.length < 50 ? res.tracks.items.length : 50

          for (let i = 0; i < total; i++) {
            const currTrack = res.tracks.items[i]
            if (!currTrack) {
              containsInvalidTracks = true
              createAlert('This is an invalid Spotify URL')
              // Remove last added id from ids
              this.playlistId = null
            }
          }
        } else {
          createAlert('Something went wrong. Try again.')
        }

        if (!containsInvalidTracks) {
          // Set tracks
          const tracks = res.tracks.items.map(item => item.track).filter((item, idx) => idx < 50).filter(track => !this.tracks.map(tr => tr.id).includes(track.id))
          // Add playlist tracks to songsIds for next search
          const remainingTracks = tracks.map(track => track.id).filter(trackId => !this.songIds.includes(trackId))

          this.songIds.push(...remainingTracks)
          this.tracks.push(...tracks)
        }
      })
  }

  async getTracks () {
    if (!this.songIds.length) {
      console.warn('No tracks added.')
      this.tracks = []
      return
    }

    if (this.songIds.length > 49) {
      console.warn('No tracks added. Max. tracks reached.')
      return
    }

    console.log(this.songIds.length)

    // Comma separated ids for Spotify
    const commaIds = this.songIds.join(',')

    await window.fetch(`${this.base}/tracks?ids=${commaIds}`, {
      method: 'get',
      headers: new window.Headers({
        Authorization: 'Bearer ' + this.token
      })
    })
      .catch(error => {
        console.error(error)
        createAlert(error)
      })
      .then(response => response.json())
      .then(res => {
        let containsInvalidTracks = false

        if (res && res.tracks) {
          for (let i = 0; i < res.tracks.length; i++) {
            const currTrack = res.tracks[i]
            if (!currTrack) {
              containsInvalidTracks = true
              // createAlert('This is an invalid Spotify URL')
              // Remove last added id from ids
              const playlistId = this.songIds.pop()
              // Check if it's a playlist
              this.playlistId = playlistId
              // this.getPlaylist()
            }
          }
        } else {
          createAlert('Something went wrong. Try again.')
        }

        if (!containsInvalidTracks) {
          this.tracks = res.tracks
        }
      })

    if (this.playlistId) {
      await this.getPlaylist()
    }
  }

  async getArtists () {
    if (!this.artistIds.length) {
      console.warn('No artists added.')
      this.artistIds = []
      return
    }

    // Comma separated ids for Spotify
    const commaIds = this.artistIds.join(',')

    await window.fetch(`${this.base}/artists?ids=${commaIds}`, {
      method: 'get',
      headers: new window.Headers({
        Authorization: 'Bearer ' + this.token
      })
    })
      .catch(error => {
        console.error(error)
        createAlert(error)
      })
      .then(response => response.json())
      .then(res => {
        let containsInvalidArtists = false

        if (res && res.artists) {
          for (let i = 0; i < res.artists.length; i++) {
            const currArtist = res.artists[i]
            if (!currArtist) {
              containsInvalidArtists = true
              createAlert('This is an invalid Spotify URL')
              // Remove last added id from ids
              this.artistIds.pop()
            }
          }
        } else {
          createAlert('Something went wrong. Try again.')
        }

        if (!containsInvalidArtists) {
          this.artists = res.artists
        }
      })
  }
}

export default Spotify
