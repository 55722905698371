// Modernizr
import 'root/.modernizrrc'

// Stylesheets
import '@/scss/style'

// Scripts
import '@/js/components/animate'
import '@/js/components/navigation'
import '@/js/components/rsr-request'
import '@/js/components/report'
import '@/js/components/toggle'
import '@/js/components/owners'
import '@/js/components/charts'
import '@/js/components/hash'
import '@/js/components/lazy-image'
import '@/js/components/sliders'
import '@/js/components/scrollspy'
import '@/js/components/pricing'
