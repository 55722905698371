import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'

class Owner {
  constructor (el) {
    this.el = el
    this.toggler = this.el.querySelector('[data-owner-group-toggle]')
    this.contacts = this.el.querySelector('[data-owner-group-contacts]')
    this.open = false
    this.closeText = 'Hide info'
    this.openText = this.toggler ? this.toggler.innerText : 'Show info'

    this.tl = () => gsap.timeline({
      defaults: {
        duration: this.open ? 1 : 0.6,
        ease: 'expo.out'
      }
    })

    this.init()
    this.initEvents()
  }

  toggle () {
    const tl = this.tl()
    this.open = !this.open

    if (this.open) {
      tl.to(this.contacts, {
        height: this.contacts.scrollHeight
      })
      tl.set(this.contacts, {
        clearProps: 'all'
      })
    } else if (!this.open) {
      tl.to(this.contacts, {
        height: 0
      })
    }

    this.toggler.innerText = this.open ? this.closeText : this.openText

    this.toggler.classList.toggle('button--light', this.open)
    this.el.classList.toggle('owner-open', this.open)
  }

  init () {
    const tl = this.tl()
    tl.set(this.contacts, {
      height: 0
    })

    this.el.classList.add('owner-initialized')
  }

  initEvents () {
    const clickHandler = e => this.toggle(e)
    if (this.toggler) {
      this.toggler.addEventListener('click', clickHandler)
    }
  }
}

class Tabs {
  constructor (el) {
    this.el = el
    this.buttons = this.el.querySelectorAll('[data-tabs-button]')
    this.tabs = this.el.querySelectorAll('[data-tabs-tab]')

    this.init()
    this.initEvents()
  }

  toggle (el, duration = 0.4) {
    const id = el.getAttribute('data-tabs-button')

    // Loop over buttons
    Array.from(this.buttons).forEach(buttonEl => {
      const buttonId = buttonEl.getAttribute('data-tabs-button')
      buttonEl.classList.toggle('button--light', buttonId !== id)
    })

    const tl = gsap.timeline({
      defaults: {
        duration,
        ease: 'expo.out'
      }
    })

    // Loop over tabs
    Array.from(this.tabs).forEach(tabEl => {
      const tabId = tabEl.getAttribute('data-tabs-tab')
      const offset = tabId === id ? 0.4 : 0
      tl.to(tabEl, {
        opacity: tabId === id ? 1 : 0,
        display: tabId === id ? 'block' : 'none'
      }, offset)
    })
  }

  init () {
    this.el.classList.add('tabs-initialized')

    Array.from(this.buttons).forEach((buttonEl, index) => {
      if (index === 0) {
        this.toggle(buttonEl, 0)
      }
    })
  }

  initEvents () {
    Array.from(this.buttons).forEach(el => {
      const clickHandler = () => this.toggle(el)
      el.addEventListener('click', clickHandler)
    })
  }
}

docReady(() => {
  Array.from(document.querySelectorAll('[data-owner-group]')).forEach(el => {
    // eslint-disable-next-line
    const owner = new Owner(el)
  })

  Array.from(document.querySelectorAll('[data-tabs]')).forEach(el => {
    // eslint-disable-next-line
    const tabs = new Tabs(el)
  })
})
