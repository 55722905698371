import Chart from 'chart.js'
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes'
// import a particular color scheme
// https://nagix.github.io/chartjs-plugin-colorschemes/colorchart.html
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau'
import docReady from '@/js/helpers/doc-ready'

const colorScheme = ['#1e3888', '#9c3848', '#596157', '#47a8bd', '#e3655b', '#524632', '#8f7e4f', '#e15a97', '#f5e663', '#ffad69']

console.log(Tableau20, colorScheme)

docReady(() => {
  Array.from(document.querySelectorAll('.audience-age__chart')).forEach(el => {
    const valuesStr = el.getAttribute('data-values')
    const labelsStr = el.getAttribute('data-labels')

    if (valuesStr && labelsStr) {
      const values = valuesStr.split(',')
      const labels = labelsStr.split(',')

      if (values.length && labels.length) {
        // eslint-disable-next-line
        const audienceAgeChart = new Chart(el, {
          type: 'bar',
          data: {
            datasets: [{
              barPercentage: 0.5,
              borderWidth: 0,
              data: values,
              backgroundColor: 'rgba(0, 0, 0, 1)'
            }],
            labels: labels
          },
          options: {
            maintainAspectRatio: false,
            legend: false,
            scales: {
              xAxes: [{
                gridLines: false,
                ticks: {
                  display: true,
                  fontSize: 10,
                  fontFamily: 'objektiv-bold',
                  color: '#212224',
                  padding: 10
                }
              }],
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  display: true,
                  stepSize: 20,
                  // max: 100,
                  min: 0,
                  fontSize: 0.1
                },
                gridLines: {
                  color: 'rgba(224, 224, 224, 0.5)'
                }
              }]
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const dataset = data.datasets[tooltipItem.datasetIndex]
                  // const total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                  // return Number(previousValue) + Number(currentValue)
                  // }, 0)
                  const currentValue = dataset.data[tooltipItem.index]
                  // const percentage = Math.floor(((currentValue / total) * 100) + 0.5)
                  return currentValue + '%'
                }
              }
            }
          }
        })
      }
    }
  })

  Array.from(document.querySelectorAll('.ownership__chart')).forEach(el => {
    const valuesStr = el.getAttribute('data-values')
    const labelsStr = el.getAttribute('data-labels')
    const colorsStr = el.getAttribute('data-colors')

    if (valuesStr && labelsStr) {
      const values = valuesStr.split(',')
      const labels = labelsStr.split(',')
      const colors = colorsStr.split(',')

      if (values.length && labels.length) {
        // eslint-disable-next-line
        const donutChart = new Chart(el, {
          type: 'doughnut',
          data: {
            datasets: [{
              borderWidth: 1,
              borderColor: '#fff',
              data: values
            }],
            labels: labels
          },
          options: {
            legend: false,
            cutoutPercentage: 80,
            plugins: {
              colorschemes: {
                scheme: colors
              }
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const dataset = data.datasets[tooltipItem.datasetIndex]
                  // const total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                  // return Number(previousValue) + Number(currentValue)
                  // }, 0)
                  const currentValue = dataset.data[tooltipItem.index]
                  // const percentage = Math.floor(((currentValue / total) * 100) + 0.5)
                  return currentValue + '%'
                }
              }
            }
          }
        })
      }
    }
  })

  Array.from(document.querySelectorAll('.m-fanbase__chart')).forEach(el => {
    const valuesStr = el.getAttribute('data-values')
    const labelsStr = el.getAttribute('data-labels')

    if (valuesStr && labelsStr) {
      const values = valuesStr.split(',')
      const labels = labelsStr.split(',')

      if (values.length && labels.length) {
        // eslint-disable-next-line
        const fanbaseDonutChart = new Chart(el, {
          type: 'doughnut',
          data: {
            datasets: [{
              borderWidth: 0,
              data: values
            }],
            labels: labels
          },
          options: {
            legend: {
              position: 'right',
              labels: {
                boxWidth: 15,
                fontSize: 16,
                fontFamily: 'objektiv-mk2',
                color: '#212224',
                padding: 28,
                usePointStyle: true
              }
            },
            cutoutPercentage: 80,
            plugins: {
              colorschemes: {
                scheme: colorScheme
              }
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const dataset = data.datasets[tooltipItem.datasetIndex]
                  const total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                    return Number(previousValue) + Number(currentValue)
                  }, 0)
                  const currentValue = dataset.data[tooltipItem.index]
                  const percentage = Math.floor(((currentValue / total) * 100) + 0.5)
                  return percentage + '%'
                }
              }
            }
          }
        })
      }
    }
  })

  Array.from(document.querySelectorAll('.audience-gender__age-bar')).forEach(el => {
    // const label = el.getAttribute('data-label')
    const men = el.getAttribute('data-percentage-men')
    const women = el.getAttribute('data-percentage-women')

    // eslint-disable-next-line
    const ageDonutChart = new Chart(el, {
      type: 'doughnut',
      data: {
        datasets: [{
          borderWidth: 0,
          data: [men, women]
        }],
        labels: ['Men', 'Women']
      },
      options: {
        cutoutPercentage: 80,
        legend: {
          display: false
        },
        plugins: {
          colorschemes: {
            scheme: ['#000', '#F4F4F4']
          }
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              return dataset.data[tooltipItem.index] + '%'
            }
          }
        }
      }
    })
  })

  Array.from(document.querySelectorAll('.audience-gender__mw')).forEach(el => {
    const men = el.getAttribute('data-percentage-men')
    const women = el.getAttribute('data-percentage-women')

    // eslint-disable-next-line
    const mwDonutChart = new Chart(el, {
      type: 'doughnut',
      data: {
        datasets: [{
          borderWidth: 0,
          data: [men, women]
        }],
        labels: ['Men', 'Women']
      },
      options: {
        cutoutPercentage: 80,
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            boxWidth: 15,
            fontSize: 16,
            fontFamily: 'objektiv-mk2',
            color: '#212224',
            padding: 0,
            usePointStyle: true
          }
        },
        plugins: {
          colorschemes: {
            scheme: ['#000', '#F4F4F4']
          }
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex]
              return dataset.data[tooltipItem.index] + '%'
            }
          }
        }
      }
    })
  })
})
